body {
    max-width: 420px;
    margin: 0 auto !important;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
